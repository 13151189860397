import styled from "styled-components"
import PrivateRoute from "../withPrivateRoute"
import { LoadingOutlined } from "@ant-design/icons"
import { Loader } from "@project/shared"

const InitalLoader = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Homepage = () => {
  const antIcon = <LoadingOutlined />

  return (
    <>
      <InitalLoader>
        <Loader indicator={antIcon} size="large" />
      </InitalLoader>
    </>
  )
}

export default PrivateRoute(Homepage)
